.border {
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.borderBottom {
  border-bottom: 1px solid #d8d8d8;
}
.borderLeft {
  border-right: 1px solid #d8d8d8;
}
.allBorder {
  border: 2px solid #d8d8d8;
  border-radius: 5px;
}
.elementor-shape{
  position: absolute;
  display: inline-block;
  overflow: hidden;
  transform: rotate(180deg);
  z-index: -1;
margin-top: 450px;
}
.elementor-shape::before{
  position: absolute;
  bottom: 0;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}
.elementor-shape svg{
      height: 500px;
       
display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
        transform: translateX(-50%);
 
}

.elementor-shape-fill{
      fill: #F0EEF1;
  
    transform-origin: center;
   
    transform: rotateY(0deg);
}
.elementor-shape2{
  position: absolute;
  display: inline-block;
  overflow: hidden;
      transform: rotate(180deg);
  z-index: -1;
margin-top: 500px;
  
}
.elementor-shape2::before{
    
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}
.elementor-shape2 svg{
     height: 300px;
    transform: translateX(-50%) rotateY(180deg);
 display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
}

.elementor-shape-fill2{
     fill: #F0EEF1;
  transform-origin: center;
   
   
    transform: rotateY(0deg);
}
.ad_service_img{
   background-image: url("../assets/targetSheet.jpg");
display: flex;
  /* Full height */
  height: 800px;
  width: 500px;
justify-content:  "flex-end";
  /* Center and scale the image nicely */
     background-position: -610px 0px;
    background-repeat: no-repeat;
    background-size: cover;
}
.amazon_service_btn{
  text-decoration: none;color: #273272,
}
@media (max-width:500px) {
  .elementor-shape{
    margin-top: 1200px;
  }
  .elementor-shape2{
    margin-top: 800px;
  }
  .ad_service_img{
       background-image: url("../assets/targetSheet.jpg");
display: flex;
  /* Full height */
  height: 300px;
  width: 700px;
justify-content:  "flex-end";
  /* Center and scale the image nicely */
     background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}