.nav_bar_wrapper{
	background: whitesmoke;
	width: 100%;
	height: 80px;
}
.mbl_menu_slide{
	background: rgb(11, 112, 203);
}
.mbl_nav_items_box{
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0px 20px;
}
.mbl_nav_ContactUS{
	height: 50px;
	width: 100%;
	background-color: #d7292e;
	display: flex;
	color: white;
	font-size: 20px;
	font-weight: 600;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
}
.project_banneR_box{
	max-width: 1440px;
	padding: 50px 20px;
	display: flex;
	margin: auto;
	justify-content: center;
}
.projects_banner_haeadings{
	font-size: 60px;
	text-align: center;
	font-weight: 700;
	color: white;
}
.featured_projects_box{
	background: url("../../assets/projects.png");
	background-position: center;
	border-top: 2px solid white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
	background-color: #262e4b;
	height: 500px;

}
.mbl_nav_items{
	height: 50px;
	color: rgb(11, 112, 203);
	font-size: 20px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	background-color:white;
	width: 100%;
}
.mbl_nav_profile{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px 5px 20px;
}
.nav_close_icon{
	font-size: 30px;
	cursor: pointer;
	color: #d7292e !important;
}
.mobile_vav_wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	height: 70px;
	background: whitesmoke;
}
.mbl_menu_icon{
	font-size: 30px;
	color: #d7292e;
	cursor: pointer;
}
.nav_bar_inner_box{
	max-width: 1440px;
	margin: auto;
	padding: 0px 20px;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.nav_logo{
	cursor: pointer;
	width: 110px;
}
.nav_items{
	font-size: 18px;
	color: black;
	cursor: pointer;
	font-weight: 500;
}
.icon_mbl{
	z-index: 9 !important;
}
.nav_contact_us{
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #d7292e;
	color: white;
	font-size: 18px;
	width: 200px;
	height: 48px;
	border-radius: 8px;
	font-weight: 600;
}
.mobile_vav_wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	height: 70px;

}
.nav_contact_us:hover{
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #d7292e;

	font-size: 18px;
	background-color: white;
	width: 200px;
	height: 48px;
	border-radius: 8px;
	font-weight: 600;
}
.nav_items:hover{
	font-size: 19px;
	color: #d7292e;
	cursor: pointer;
	font-weight: 700;
}
.nav_content_wrappper{
	display: flex;
	justify-content: space-between;
	gap:30px;
align-items: center;
}
@media screen and (max-width:1000px){
	.nav_content_wrappper{
		display: flex;
		justify-content: space-between;
		gap:15px;
	align-items: center;
	}
	.nav_items{
		font-size: 16px;
		color: black;
		cursor: pointer;
		font-weight: 500;
	}
	.featured_projects_box{
		background: url("../../assets/projects.png");
		background-position: center;
		border-top: 2px solid white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #262e4b;
		height: 400px;
	
	}
	.nav_items:hover{
		font-size: 17px;
		color:#d7292e;
		cursor: pointer;
		font-weight: 700;
	}
}
@media screen and (max-width:850px){
	.nav_bar_wrapper{
		display: none;
	}
	.featured_projects_box{
		background: url("../../assets/projects.png");
		background-position: center;
		border-top: 2px solid white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #262e4b;
		height: 350px;
	
	}
}
@media screen and (min-width:851px){
	.mobile_vav_wrapper{
		display: none;
		justify-content: space-between;
		align-items: center;
		padding: 0px 20px;
		height: 70px;
		background: whitesmoke;
	}
}