.blog_banner{
    background-image: linear-gradient(
        292deg,
        #fc766aff 0%,
        #5b84b1ff 100%
      ) !important;
        background-color: #2d77ff !important;
        height: 500px;
        border-top: 2px solid white;
        padding-left: 90px;

}
.blog_banner_contaner{

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hash_tag{
    display: flex;
    padding-top: 20px;
    align-items: center;
    color: white;
}
.blog_b_haeding{
	font-size: 20px;
	font-family: 'DM Sans', sans-serif;
	color: white;
	font-weight: 500;
	padding-top: 30px;
    
}

.blog_b_text{
	font-size: 50px;
	color: white;
	font-family: 'DM Sans', sans-serif;
	font-weight: 500;
}
.current_date{
    color: white;
    font-size: 20px;
    padding-top: 20px;
}
.type{
    display: flex;
    padding-top: 20px;
    align-items: center;
    color: white;
}
.blog_terms_wrapper_left {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .blog_terms_wrapper_right{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #2d77ff;
    height: 150px;
    padding: 20px;
    background-image: linear-gradient(
        292deg,
        #fc766aff 0%,
        #5b84b1ff 100%
      ) !important;
        background-color: #2d77ff !important;
  }
.blog_title_right{
    width: 160px;
    color: white;
}
.btn_conatiner{
    padding-top: 15px;
}
.blog_button_right{
    width: 150px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    color: linear-gradient(
        292deg,
        #fc766aff 0%,
        #5b84b1ff 100%
      ) !important;
        color: black;
}
  .desc{
    color:'black';
    padding-bottom: 10px;
    
  }
  .title{
    color: "black";
    height:40px;
    font-size: 18px;
    font-weight: bold;
  }
  .blog_img{
    padding-top: 20px;
    padding-bottom: 20px;
    
  }
  @media screen and (width<901px) {
    .blog_banner{
        background-image: linear-gradient(
            292deg,
            #fc766aff 0%,
            #5b84b1ff 100%
          ) !important;
            height: 680px;
            border-top: 2px solid white;
            padding-left: 20px;
            padding-right: 20px;
    }
    .blog_b_text{
        font-size: 40px;
        color: white;
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
    }
    .title{
        color: "black";
        height:48px;
        font-size: 17px;
        font-weight: bold;
      }
   
  }