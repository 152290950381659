.request_button_web{
    
            text-decoration: none;
            font-size: 17px;
            font-weight: 600;

            margin-top: 30px;
           
            width: 225px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            padding-left: 10px;
            padding-right: 10px;
            
      }
      /* .request_button_web:hover{
            color:white
      } */
      .request_button_mobile{
            color: white;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            color: white;
            margin-top: 30px;
            background-color: #CA2C47;
            width: 220px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px
}
            .request_button_mobile:hover{
                  color:white
            }