* {
  margin: 0;
  scroll-behavior: smooth;
}
#header {
  width: 100%;
  height: 100vh;
  background-image: url("https://bitsbayltd.com/static/media/2.4db4419f.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.logo {
  cursor: pointer;
}
.mainHeader {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20%;
}
.icon {
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.contact {
  margin-top: 9px;
  margin-left: 18px;
  color: white;
  cursor: pointer;
}
.test:hover {

  color: red;
}

.menuLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: #d7292e;
  padding-right: 20px;
  width: 90px;
  height: 40px;
  margin-left: 20px;
  border-radius: 5px;
  color: white;
}
.textContainer {
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.textContainer h1 {
  font-size: 40px;
  color: white;
}
h2 {
  font-size: 40px;
  color: white;
  margin-left: 63px;
}
h3 {
  font-size: 20px;
  color: white;
  margin: 60px;
  border-width: thick;
}
.textContainer p {
  font-size: 20px;
  color: white;
}
.ss {
  border-width: 1;
  border-color: aliceblue;
}
.stack {
  border: 1px solid #d8d8d8;
}
.borderNone:focus,
textarea:focus,
select:focus {
  outline: none;
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  width: 100%;
}

img.hover-zoom:hover {
  transform: scale(1.25);
}
.myList:hover {
  background: "#F6F7F8";
}

.myList:hover {
  background-color: #f6f7f8;
}
.gradient {
  background-image: linear-gradient(
    292deg,
    #fc766aff 0%,
    #5b84b1ff 100%
  ) !important;
  background-color: #2d77ff !important;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}
.pagination .page-num {
  padding: 4px 4px ;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}
.page-num2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px ;
  background-color: #f6f7f8;
  font-size: 13px;
  font-weight: 400;
  width: 30px;
  cursor: pointer;
  border-radius: 20px;
  text-decoration: none;
}
.pagination .page-num:hover {
  background-color: #5ab1bb;
  display: flex;
  justify-content: center;
  color: white;
}
.pagination .active {
  background-color: #5ab1bb;
  color: white;
}
.bgImg {
  background-image: url("https://addevice.io/wp-content/uploads/2021/05/WHAT-IS-NATIVE-APP-DEVELOPMENT.png");

  padding-top: 55px !important;
  padding-right: 50px !important;
  padding-bottom: 120px !important;
  padding-left: 50px !important;
}
.bgImg2 {
  background-image: url("https://ripenapps.com/blog/wp-content/uploads/2018/09/Hybrid-App-Development.png");

  padding-top: 55px !important;
  padding-right: 50px !important;
  padding-bottom: 120px !important;
  padding-left: 50px !important;
}
.pressRelease:hover {
  background-color: #e73f33;
  color: white;
}
.blog:hover {
  background-color: #e73f33;
  color: white;
}
.footer{
 margin-top: 10px;

}
.footer a{
  color: "#000" 
}
.footer a:hover{
color: gray;
}
.blog_background{
	background: url("./assets/blog.jpg");
	height: 600px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 2px solid white;	
}
