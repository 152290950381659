.Container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.heading {
  align-items: center;
  justify-content: center;
}
.legcy_content_wraper{
  max-width: 1000px;
  margin: auto;
  padding-top: 20px;
  font-size: 21px;
  font-family: 'DM Sans', sans-serif;
  text-align: center;
}
.heading h1{
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 40px;
  margin-bottom: 25px;
}

.imgContainer {
 
  margin-top: 120px;
display: flex;
}
.imgContainer_div{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  justify-content:space-evenly;
}
.imgContainer_div h1{
  font-size: 16px;
 
  margin-top: 20px;
}
.bts {
  font-size: 25px;
  color: #262e4b;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}
p {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:768px){
  .legcy_content_wraper{
font-size: 16px;
padding: 0px 20px;
text-align: center;
  }
  .imgContainer {
    width: 100%;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 20px;
    justify-content: space-around;
    /* margin-top: 80px; */
  }
  
  .imgContainer_watch{
    position: relative;
    left: -15px;
  }
}