.portfolio9_wrapper {
  max-width: 1440px;
  margin: auto;
  padding: 100px 20px 50px 20px;
  display: flex;
  gap: 100px;

  justify-content: space-between;
}
.serice_type {
  font-size: 16px;
  color: #d7292e;
  text-decoration: underline;
}
.drop_donw_items_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}
.portflio_left {
  width: 100%;
}
.logo_ss {
  border-radius: 14px;
}
.nav_serivice_hover {
  position: relative;
}
.serves_items {
  font-size: 14px;
  color: #686767;
}
.services_drop_downs {
  width: 900px;
  left: -220px;
  display: none;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  top: 25px;
  padding: 10px 20px;
  border-radius: 14px;
  position: absolute;
  height: 350px;
  background-color: whitesmoke;
  border: 1px solid #d4cfcf;
  z-index: 100;
}
.nav_serivice_hover:hover .services_drop_downs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.portfolio_heading {
  font-size: 40px;
  font-family: "DM Sans", sans-serif;
margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}
.portfolio_items {
  height: 260px;
  cursor: pointer;
  width: 270px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-top: 10px;
  border-radius: 14px;
}

.portfolio_items:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  border: 2px solid #d7292e;
}
.seprate_point1 {
  height: 20px;
  width: 20px;
  top: -10px;
  left: -10px;
  right: 0;
  position: absolute;
  border-radius: 50%;
}
.seprate_point2 {
  height: 20px;
  width: 20px;

  left: -10px;
  right: 0;
  bottom: -10px;
  position: absolute;
  border-radius: 50%;
}
.portfolio_seprator {
  height: 105vh;
  border-radius: 8px;
  position: relative;
  border: 2px solid;
}
.portfilio_box {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  /* padding: 50px 0px; */
  justify-content: space-between;
}
@media screen and (max-width: 1440px) {
  .portfolio_items {
    height: 200px;
    cursor: pointer;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 10px;
    border-radius: 14px;
  }

  .portfolio_items:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  }
  .portfilio_box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 50px 0px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1181px) {
  .portfilio_box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 50px 0px;
    justify-content: center;
  }
  .portfolio_heading {
    font-size: 30px;

    text-align: center;
    font-weight: 700;
  }
}
@media screen and (max-width: 1040px) {
  .services_drop_downs {
    width: 700px;
    left: -150px;
    display: none;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    top: 45px;
    padding: 10px 20px;
    border-radius: 14px;
    position: absolute;
    height: 350px;
    background-color: whitesmoke;
    border: 1px solid #d4cfcf;
  }
}
@media screen and (max-width: 700px) {
  .portfolio_seprator {
    display: none;
  }
  .portfolio9_wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 50px 20px 50px 20px;
    display: flex;
    gap: 20px;

    justify-content: space-between;
  }
}
@media screen and (max-width: 600px) {
  .portfolio9_wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 50px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .services_drop_downs {
    width: 340px;
    left: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;

    top: 40px;
    padding: 10px 20px 10px 20px;
    border-radius: 14px;
    position: absolute;
    height: auto;
    background-color: whitesmoke;
    border: 1px solid #d4cfcf;
  }
  .drop_donw_items_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
  }
}
