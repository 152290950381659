.Banner_wrapper{
	background: url("../assets/bitsbay.jpg");
	
	height: 600px;
	border-top: 2px solid ;
	background-position: left;
	background-size: cover;
	
}
.project_demo_container{
	max-width: 1440px;
	margin: auto;
	padding: 50px 20px;
}
.demos_box{
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
}
.banner_headings{
	font-size: 50px;
	
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.banner_logo{
	height: 320px;
	width: 400px;
}
.banner_content_wrapper{
	display: flex;
	flex-direction: column;
	padding: 20px 20px;
	justify-content: center;
	align-items: center;
}
.b_social_items{
	height: 40px;
	padding: 8px;
	width: 40px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner_social_media{
	display: flex;
	padding-top: 50px;
	justify-content: center;
	align-items: center;
	gap: 50px;
}

.heading555{
	padding-top: 80px;
}
.footer_main_box{
	max-width: 1440px;
	margin: auto;
    flex-wrap: wrap;
    gap: 50px;
	display: flex;
	justify-content: space-between;
	padding: 50px 20px;

}

.footer_point{
	font-size: 22px;
	font-weight: 700;

	font-family: 'DM Sans', sans-serif;
}
.company_story_heaeding{
	text-align: center;
	font-size: 40px;
	color: black;
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.company_story_heaeding2{
	text-align: center;
	font-size: 40px;
	margin-top: 40px;
	color: black;
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.stroy_text{
	max-width: 1000px;
	text-align: center;
	padding-top: 50px;
	margin: auto;
	font-size: 22px;
	font-family: 'DM Sans', sans-serif;
	color: black;
}
.company_content_box{
	max-width: 1440px;
	margin: auto;
	padding: 100px 20px;
}
.comapnuy_b_text{
	font-size: 32px;
	color: white;
	max-width: 1000px;
	text-align: center;
	padding-top: 50px;
	font-family: 'DM Sans', sans-serif;
	font-weight: 500;
}
.comapny_b_haeding{
	font-size: 50px;
	font-family: 'DM Sans', sans-serif;
	color: white;
	font-weight: 700;
	padding-top: 100px;
}

.company_banner{
background: url("../assets/company.jpg");
height: 600px;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-top: 2px solid white;
}
.contact_banner{
	background: url("../assets/images(3).jpg");
	height: 600px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 2px solid white;	
}
.technolgy_banner{
background: url("../assets/contact.jpg");
height: 600px;
background-position: center;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
background-size: cover;
border-top: 2px solid white;
}
.company_banner_contaner{

	margin: auto;
	max-width: 1440px;
	padding: 0px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.technologies_wrapper{
	max-width: 1440px;
	margin: auto;
	padding: 0px 20px;
}

.mulitivertical_wrapper{


	padding-bottom: 80px;
	margin-top: 150px;

}
.sucess_story_wrapper{
	max-width: 1440px;
	margin: auto;
	padding: 0px 20px;
}
.banner_text{
	
	font-weight: 700;
	font-size: 30px;
	font-family: 'DM Sans', sans-serif;
}
.banner_text2{
	font-weight: 700;
	font-size: 20px;
	font-family: 'DM Sans', sans-serif;
}
.secc_heaing{
	font-size: 30px;
	text-align: center;
	font-weight: 700;

	font-family: 'DM Sans', sans-serif;
}
.technolgy_box{
	height: 200px;
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;

	border-radius: 14px;
	border: 1px solid #262e4b;
}
.technolgy_box:hover{
	cursor: pointer;
		transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

}
.navi_lins{
	text-decoration: none !important;
	
}
.techlogt_itmes{
	display: flex;
	width: 100%;
	padding: 40px 0px;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
}
.multi_text_box{
	margin: auto;
	
	padding-top: 50px;
	text-align: center;
	font-size: 25px;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1000px;
}
.link_type{
	text-decoration: none !important;
}
.multi_heading{
	text-align: center;
	font-size: 40px;

	padding-top: 50px;
	font-weight: 700;
	font-family: 'DM Sans', sans-serif;
}
.technolgy_heading{
	text-align: center;
	font-size: 40px;
	color: black;
	padding-top: 50px;
	font-weight: 700;
	font-family: 'DM Sans', sans-serif;
}
.multi_card_title{
	font-size: 20px;
	font-weight: 600;

	text-align: center;
	padding-top: 10px;
}
.multi_small_box{
	/* max-width: 1440px; */
	padding: 50px 20px;
	display: flex;
	/* flex-wrap: wrap; */
	margin: auto;
	justify-content: space-between;
	align-items: center;
}
@media screen and (max-width:1000px){
	.multi_text_box{
		margin: auto;
	
		padding-top: 30px;
		text-align: center;
		font-size: 20px;
		padding-left: 20px;
		padding-right: 20px;
		max-width: 1000px;
	}
	.company_banner{
		background: url("../assets/company.jpg");
		height: 500px;
		background-position: center;
		border-top: 2px solid white;
		}
		.contact_banner{
			background: url("../assets/images(3).jpg");
			height: 1000px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border-top: 2px solid white;	
		}
		.technolgy_banner{
			background: url("../assets/contact.jpg");
			height: 500px;
			background-position: center;
			background-repeat: no-repeat;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;
			border-top: 2px solid white;
			}
		.banner_headings{
			font-size:  40px;
			
			font-family: 'DM Sans', sans-serif;
			font-weight: 700;
		}
	.comapny_b_haeding{
		font-size: 40px;
		font-family: 'DM Sans', sans-serif;
		color: white;
		font-weight: 700;
		padding-top: 80px;
	}
	.techlogt_itmes{
		display: flex;
		width: 100%;
		padding: 40px 0px;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;
	}
	.company_content_box{
		max-width: 1440px;
		margin: auto;
		padding: 80px 20px;
	}
	.comapnuy_b_text{
		font-size: 25px;
		color: white;
		max-width: 1000px;
		text-align: center;
		padding-top: 30px;
		font-family: 'DM Sans', sans-serif;
		font-weight: 500;
	}
}
@media screen and (max-width:768px){
	.banner_text{
	color: white;
	font-weight: 700;
	text-align: center;
	font-size: 20px;
	font-family: 'DM Sans', sans-serif;
}
.company_story_heaeding2{
	text-align: center;
	font-size: 30px;
	margin-top: 40px;
	color: black;
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.banner_text2{
	color: white;
	font-weight: 700;
	text-align: center;
	font-size: 16px;
	font-family: 'DM Sans', sans-serif;
}
.company_content_box{
	max-width: 1440px;
	margin: auto;
	padding: 30px 20px;
}
.banner_headings{
	font-size: 30px;

	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.heading555{
	padding-top: 50px;
}
.stroy_text{
	max-width: 1000px;
	text-align: center;
	padding-top: 20px;
	margin: auto;
	font-size: 18px;
	font-family: 'DM Sans', sans-serif;
	color: black;
}
.footer_point{
	font-size: 22px;
	font-weight: 700;
	color: black;
	text-align: center;
	font-family: 'DM Sans', sans-serif;
}
.Banner_wrapper{
	background: url("../assets/bitsbay.jpg");
	height: 600px;
	border-top: 2px solid white;
	background-position: left;
	background-size: cover;
	
}
.banner_content_wrapper{
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	justify-content: center;
	align-items: center;
}
.banner_logo{
	margin-top: -50px;
	height: 300px;
	width: 350px;
}
.footer_main_box{
	max-width: 1440px;
	margin: auto;
flex-wrap: wrap;
gap: 30px;
	display: flex;
	justify-content: center;
	padding: 50px 20px;
	background:whitesmoke;
}
.company_banner{
	background: url("../assets/company.jpg");
	height: 350px;
	background-position: center;
	border-top: 2px solid white;
	}
	.contact_banner{
		background: url("../assets/images(3).jpg");
		height: 1000px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-top: 2px solid white;	
	}
	.technolgy_banner{
		background: url("../assets/contact.jpg");
		height: 350px;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: cover;
		border-top: 2px solid white;
		}
.comapny_b_haeding{
	font-size: 32px;
	font-family: 'DM Sans', sans-serif;
	color: white;
	font-weight: 700;
	padding-top: 50px;
	text-align: center;
}
.company_story_heaeding{
	text-align: center;
	font-size: 30px;
	color: black;
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
}
.comapnuy_b_text{
	font-size: 20px;
	color: white;
	max-width: 1000px;
	text-align: center;
	padding-top: 30px;
	font-family: 'DM Sans', sans-serif;
	font-weight: 500;
}

}
@media screen and (max-width:639px){
	.multi_small_box{
		max-width: 1440px;
		padding: 50px 20px;
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
		margin: auto;
		justify-content: center;
		align-items: flex-end;
	}
	.banner_headings{
		font-size: 32px;
		
		font-family: 'DM Sans', sans-serif;
		font-weight: 700;
		display:flex ;
		text-align: center;
	}
}