.request-button-2-web {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 50px;
  background-color: #ff9600;
  width: 360px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 30px;
}
.request-button-2-web:hover{
           color:white
     }
     .request-button-2-mobile {
       font-size: 15px;
  font-weight: 600;
  color: white;
  margin-top: 50px;
  background-color: #FF9600;
  text-decoration: none;
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
   margin-bottom: 30px;
}

       .request-button-2-mobile:hover{
                  color:white
            }