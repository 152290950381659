.project_demo_container {
  display: flex;
  flex-direction: column;
  /* gap: 20px; Add spacing between child elements */
  padding: 20px; /* Add padding for better content spacing */
}

.project_demo_banner {
  background-position: center;
  border-top: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #262e4b;
  height: 500px;
  padding: 20px; /* Add padding for better alignment */
}

.project_banner_logo {
  max-height: 300px; /* Ensure a consistent maximum height */
  width: auto; /* Maintain aspect ratio */
}

.description {
  margin-bottom: 15px;
  font-size: 16px;
  width: 50%; /* Full width for better alignment */
  /* text-align: justify; Better text alignment */
  color: #333; /* Slightly darker text color for readability */
}

.titles {
  font-size: 26px;
  font-weight: bold;
  /* text-align: center; Center the titles for better emphasis */
  color: #444; /* Subtle text color */
  margin-bottom: 10px; /* Add space below titles */
}

.carousel_container {
  width: 100%;
  /* max-width: 1200px; Limit max width for larger screens */
  margin: 0px auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding:10px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.project_carousel img {
  max-width: 100%;
  height: auto; /* Ensure images scale proportionally */
  object-fit: contain;
  display: block;
  
  border-radius: 10px;
 
}


@media (max-width: 950px) {
  .description {
   width: 70%;
  }
}
@media (max-width: 768px) {
  .project_demo_banner {
    height: 300px; /* Reduce banner height on smaller screens */
    padding: 10px;
  }

  .project_banner_logo {
    max-height: 200px;
  }
  .carousel_container {
   padding: 0;
  }
  .titles {
    font-size: 22px;
  }

  .description {
    font-size: 14px;  width: 80%;
  }
}

@media (max-width: 480px) {
  .project_demo_banner {
    height: 200px;
  }
  .project_banner_logo {
    max-height: 80px;
  }
  .titles {
    font-size: 20px;
  }

  .description {
    font-size: 13px;
  }
}
